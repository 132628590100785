import React, { FunctionComponent, useEffect } from 'react';
import { useRouter } from 'next/router';
import asyncLogger from '../../common/logger';
import PageLoader from './PageLoader';
import { NamedRoute, RoutePageProps } from '../../common/hooks/useNamedRoute';
import { logout } from '../../common/helpers/login';
import { useAccountInfo } from '../../common/hooks/AccountInfoProvider';

const LogoutPage: FunctionComponent<RoutePageProps> = () => {
    const router = useRouter();
    const { setCurrentLoanId } = useAccountInfo();

    useEffect(() => {
        setCurrentLoanId(null);
        logout().catch(asyncLogger.error);
        router.push(NamedRoute.LOGIN);
        // eslint-disable-next-line
    }, []);

    return <PageLoader title="Logging out..." />;
};

export default LogoutPage;
